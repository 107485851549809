<template>
  <div class="login">
    <el-steps :active="active" finish-status="success" simple style="margin-top: 20px">
      <el-step title="用户信息"></el-step>
      <el-step title="公司信息"></el-step>
      <el-step title="注册完成"></el-step>
    </el-steps>
    <div class="login-main">
      <div v-show="active===0">
        <el-form style="width: 400px;" ref="loginForm" :model="merchant" :rules="loginRules" class="login-form">
          <h3 class="title">经纪人注册</h3>
          <el-form-item prop="PHONE">
            <el-input prefix-icon="el-icon-phone" v-model="merchant.PHONE" type="tel" placeholder="请输入11位手机号码" clearable/>
          </el-form-item>
          <el-form-item prop="vcode">
            <el-row>
              <el-col :span="14">
                <el-input prefix-icon="el-icon-message" v-model="merchant.vcode" auto-complete="off" placeholder="验证码" clearable></el-input>
              </el-col>
              <el-col :span="10" style="text-align: right">
                <el-button native-type="button" type="primary" :disabled="disabled" size="small" round
                           @click="sendcode();">
                  {{ getcode }}
                </el-button>
              </el-col>
            </el-row>
          </el-form-item>

          <el-form-item prop="PASSWORD">
            <el-input prefix-icon="el-icon-lock" v-model="merchant.PASSWORD" type="password" placeholder="请设置密码" clearable/>
          </el-form-item>
          <el-form-item prop="CHKPWD">
            <el-input prefix-icon="el-icon-lock" v-model="merchant.CHKPWD" type="password" placeholder="请确认密码" clearable/>
          </el-form-item>
          <el-row style="margin-top: 20px;">
            <el-col :span="24" style="text-align: center;">
              <div class="start-register">
                <el-button type="primary" @click="next">下一步</el-button>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <div class="attention" style="font-size: 10px;margin: 10px 0;text-align: center;">
              <p>已有账号<a target="_blank" :href="bsUrl"> 请直接登录</a></p>
            </div>
          </el-row>
        </el-form>
      </div>
      <div v-show="active===1">
        <el-form ref="companyForm" :model="merchant" :rules="companyRules" class="login-form">
          <el-row>
            <el-col :span="24">
              <el-form-item label-width="80px" label="企业名称" prop="MERCHANT_NAME">
                <el-input v-model="merchant.MERCHANT_NAME" placeholder="请输入企业名称" clearable maxlength="200" show-word-limit/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label-width="80px" label="法人姓名" prop="LEGALPERSONN">
                <el-input v-model="merchant.LEGALPERSONN" placeholder="这里输入法人姓名" clearable/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label-width="80px" label="联系电话" prop="TELPHONE">
                <el-input v-model="merchant.TELPHONE" placeholder="这里输入联系电话" clearable/>
              </el-form-item>
            </el-col>

          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label-width="80px" label="联系人" prop="CONTACT">
                <el-input v-model="merchant.CONTACT" placeholder="这里输入联系人" clearable/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label-width="80px" label="联系手机" prop="PHONE">
                <el-input label="联系手机" v-model="merchant.PHONE" placeholder="这里输入联系手机" readonly></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label-width="80px" label="企业地址" prop="ADDRESS">
                <el-input v-model="merchant.ADDRESS" placeholder="这里输入企业地址" clearable maxlength="500" show-word-limit/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label-width="80px" label="分中心" prop="LINK_CENTER_ID">
                <el-select v-model="merchant.LINK_CENTER_ID" placeholder="请选择分中心" clearable>
                  <el-option v-for="(item,index) in centerList" :key="index" :value="item.CENTER_ID"
                             :label="item.CENTER_NAME"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label-width="80px" label="服务费率" prop="TAX_POINT">
                <el-input type="number" v-model="merchant.TAX_POINT" placeholder="输入小数，如0.05" clearable/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label-width="80px" label="开票方式" prop="TAX_TYPE">
                <el-select v-model="merchant.TAX_TYPE" placeholder="选择开票方式" clearable>
                  <el-option label="一票(9%税率)" value="0"></el-option>
                  <el-option label="两票(9%税率+6%服务税率)" value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label-width="80px" label="限制上浮" prop="TAX_LIMIT">
                <el-radio-group v-model="merchant.TAX_LIMIT" direction="horizontal">
                  <el-radio label="0">否</el-radio>
                  <el-radio label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label-width="80px" label="经纪人类型" prop="CARRIER_TYPE">
                <el-select v-model="merchant.CARRIER_TYPE" placeholder="选择经纪人类型" clearable>
                  <el-option label="货主" value="1"></el-option>
                  <el-option label="物流公司" value="2"></el-option>
                  <el-option label="经纪人" value="3"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label-width="80px" label="税费支付方式" prop="PAYTAX_TYPE">
                <el-radio-group v-model="merchant.PAYTAX_TYPE" direction="horizontal">
                  <el-radio label="1">前置</el-radio>
                  <el-radio label="0">后置</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <el-form-item label-width="80px" label="经营范围" prop="QUALIFIED">
                <el-input type="textarea" v-model="merchant.QUALIFIED" placeholder="这里输入经营范围" clearable maxlength="1000" show-word-limit/>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <el-form-item label-width="80px" label="区域" prop="AREA_ID">
                <el-select v-model="provinceId" @change="changeArea" style="width:150px;height:25px;margin-right: 10px;" clearable>
                  <el-option v-for="(item,index) in areaList" :key="index" :value="item.provinceCode"
                             :label="item.provinceName"></el-option>
                </el-select>
                <el-select v-model="merchant.AREA_ID" style="width:150px;height:25px;margin-right: 10px;">
                  <el-option v-for="(item,index) in mallCityList" :key="index" :value="item.cityCode"
                             :label="item.cityName"></el-option>
                </el-select>
                <!--            <el-select v-model="merchant.AREA_ID" style="width:120px;height:25px;">-->
                <!--              <el-option v-for="(item,index) in city.mallAreaList" :key="index" :value="item.areaCode" :label="item.areaName"></option>-->
                <!--            </select>-->
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label-width="80px" label="企业信用代码" prop="QUALIFIED_CODE">
                <el-input v-model="merchant.QUALIFIED_CODE" placeholder="这里输入企业信用代码" clearable/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label-width="80px" label="道路运输许可证" prop="BUSINESS_LICENSE">
                <el-input v-model="merchant.BUSINESS_LICENSE" placeholder="这里输入营业许可证" clearable/>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label-width="80px" label="开户行" prop="OUT_BANKNAME">
                <el-input v-model="merchant.OUT_BANKNAME" placeholder="这里输入开户行" clearable/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label-width="80px" label="支行名称" prop="ACCOUNT_DETAIL">
                <el-input v-model="merchant.ACCOUNT_DETAIL" placeholder="这里输入支行名称" clearable/>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label-width="80px" label="开户名" prop="OUT_ACCOUNT_NAME">
                <el-input v-model="merchant.OUT_ACCOUNT_NAME" placeholder="这里输入开户名" clearable/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label-width="80px" label="账号" prop="OUT_ACCOUNT_NO">
                <el-input v-model="merchant.OUT_ACCOUNT_NO" placeholder="这里输入账号" clearable/>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <el-form-item label-width="80px" label="营业执照" prop="OTHER_FILE">
                <el-input v-model="merchant.OTHER_FILE" placeholder="请输入地址" clearable/>
                <el-upload
                    :limit="1"
                    :action="uploadImgUrl"
                    :file-list="otherFiles"
                    :multiple="true"
                    list-type="picture-card"
                    :on-success="handleSuccess"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove">
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible" append-to-body>
                  <img width="100%" :src="dialogImageUrl" alt="">
                </el-dialog>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row style="margin-top: 10px;">
            <el-col :span="24" style="text-align: center;">
              <div class="start-register">
                <el-button type="primary" @click="previous">上一步</el-button>
                <el-button type="primary" @click="register">注册</el-button>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <div class="attention" style="font-size: 10px;margin:10px 0;text-align: center;">
              <p>注册即为同意接受<a href="javascript:void(0);" @click="agreeDialog=true">&lt;&lt;用户服务协议&gt;&gt;</a></p>
            </div>
          </el-row>
        </el-form>
      </div>
      <div v-show="active===2">
        <el-row style="margin-top: 100px;">
          <el-col :span="24" style="text-align: center;">
            <i class="el-icon-success" style="font-size: 60px;color: #67c23a;"></i>
            <p style="font-size: 30px;color: #67c23a;">注册成功！</p>
          </el-col>
        </el-row>
      </div>

      <el-dialog
          title="使用协议"
          :visible.sync="agreeDialog"
          width="60%">
        <div class="ql-editor" v-html="agreeInfo"></div>
        <span slot="footer" class="dialog-footer">
        <el-button @click="agreeDialog = false">取 消</el-button>
        <el-button type="primary" @click="agreeDialog = false">确 定</el-button>
      </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Des from '@/utils/des';
import AreaList from '@/utils/area_data';
import axios from 'axios'
import {getContentInfo} from "../request/index";

export default {
  data() {
    let validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.merchant.PASSWORD) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    };
    return {
      bsUrl:window.apiUrl.replace("/prod-api",""),
      otherFiles: [],
      centerList: [],
      dialogImageUrl: '',
      dialogVisible: false,
      agreeDialog: false,
      agreeInfo:'',
      uploadImgUrl: window.apiTableUrl + '/api/sys-api/api/common/uploadImage',
      merchant: {
        PHONE: "",
        vcode: "",
        PASSWORD: "",
        CHKPWD: "",
        MERCHANT_NAME: "",
        LEGALPERSONN: "",
        TELPHONE: "",
        CONTACT: "",
        ADDRESS: "",
        LINK_CENTER_ID: "",
        TAX_POINT: 0.09,
        TAX_TYPE: "0",
        TAX_LIMIT: "1",
        CARRIER_TYPE: "1",
        PAYTAX_TYPE: "1",
        QUALIFIED: "",
        AREA_ID: "",
        QUALIFIED_CODE: "",
        BUSINESS_LICENSE: "",
        OUT_BANKNAME: "",
        ACCOUNT_DETAIL: "",
        OUT_ACCOUNT_NAME: "",
        OUT_ACCOUNT_NO: "",
        OTHER_FILE: ""
      },
      countdown: 60,
      getcode: "获取验证码",
      disabled: false,
      active: 0,
      areaList: AreaList,
      provinceId: '',
      mallCityList: [],
      loginRules: {
        PHONE: [{required: true, trigger: "change", message: "手机号不能为空"}, {
          pattern: /^1[3|5|7|8|9]\d{9}$/,
          message: '请输入正确的号码格式',
          trigger: 'change'
        }],
        PASSWORD: [{required: true, trigger: "blur", message: "密码不能为空"}],
        CHKPWD: [{required: true, trigger: "blur", validator: validatePass2}],
        vcode: [{required: true, trigger: "change", message: "验证码不能为空"}]
      },
      companyRules: {
        MERCHANT_NAME: [{required: true, trigger: "blur", message: "请输入企业名称"}],
        LEGALPERSONN: [{required: true, trigger: "blur", message: "请输入法人姓名"}],
        TELPHONE: [{required: true, trigger: "blur", message: "请输入联系电话"}],
        CONTACT: [{required: true, trigger: "blur", message: "请输入联系人"}],
        ADDRESS: [{required: true, trigger: "blur", message: "请输入企业地址"}],
        LINK_CENTER_ID: [{required: true, trigger: "blur", message: "请选择分中心"}],
        TAX_POINT: [{required: true, trigger: "blur", message: "请输入服务费率"}],
        TAX_TYPE: [{required: true, trigger: "blur", message: "请选择开票方式"}],
        TAX_LIMIT: [{required: true, trigger: "blur", message: "请选择限制上浮"}],
        CARRIER_TYPE: [{required: true, trigger: "blur", message: "请选择经纪人类型"}],
        PAYTAX_TYPE: [{required: true, trigger: "blur", message: "请选择税费支付方式"}],
        QUALIFIED: [{required: true, trigger: "blur", message: "请输入经营范围"}],
        AREA_ID: [{required: true, trigger: "blur", message: "请选择区域"}],
        QUALIFIED_CODE: [{required: true, trigger: "blur", message: "请输入企业信用代码"}],
        BUSINESS_LICENSE: [{required: true, trigger: "blur", message: "请输入营业许可证"}],
        OUT_BANKNAME: [{required: true, trigger: "blur", message: "请输入开户行"}],
        ACCOUNT_DETAIL: [{required: true, trigger: "blur", message: "请输入支行名称"}],
        OUT_ACCOUNT_NAME: [{required: true, trigger: "blur", message: "请输入开户名"}],
        OUT_ACCOUNT_NO: [{required: true, trigger: "blur", message: "请输入账号"}],
        OTHER_FILE: [{required: true, trigger: "change", message: "请上传营业执照"}]
      }
    }
  },
  mounted() {
    this.queryCenter();
    getContentInfo(79).then((res)=>{
      this.agreeInfo=res.data.content;
    });
  },
  methods: {
    changeArea(item) {
      this.mallCityList=[];
      this.merchant.AREA_ID='';
      for (let area of this.areaList) {
        if(area.provinceCode === item){
          this.mallCityList = area.mallCityList;
          return;
        }
      }
    },
    queryCenter() {
      axios.get(window.apiTableUrl + "/api/sys-api/app/center/queryList").then(res => {
        this.centerList = res.data.data;
      });
    },
    next() {
      this.$refs['loginForm'].validate((valid) => {
        if(valid){
          this.active = 1;
        }
      });
    },
    previous() {
      this.active = 0;
    },
    sendcode() {
      if (this.merchant.PHONE === "") {
        this.$message.error("手机号不能为空");
        return false;
      }
      this.disabled = true;  //将按钮设为不可点击
      this.getcode = "重新发送(" + this.countdown + ")";
      setInterval(this.doLoop, 1000);  //一秒执行一次

      axios.get(window.apiTableUrl + "/api/sys-api/app/sms/sendCode?PHONE=" + this.merchant.PHONE).then(res => {
        if (res.data.status == '200') {
          this.$message.success('验证码发送成功,请注意查收');
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    doLoop() {
      this.countdown--;
      if (this.countdown > 0) {
        this.getcode = "重新发送(" + this.countdown + ")";
      } else {
        clearInterval() //清楚js定时器
        this.disabled = false;
        this.getcode = "获取验证码";
        this.countdowm = 10;
      }
    },
    getDesPwd: function (pwd) {
      let desPwd = '';
      let key1 = "xmob";
      let key2 = "X";
      let key3 = "MOB";
      desPwd = Des.strEnc(pwd, key1, key2, key3);
      return desPwd;
    },
    register() {
      this.$refs['companyForm'].validate((valid) => {
        if (valid) {
          let params = Object.assign({}, this.merchant);
          params.PASSWORD = this.getDesPwd(this.merchant.PASSWORD);
          axios.post(window.apiTableUrl + "/api/sys-api/app/sysuser/register", params).then(res => {
            if (res.data.status == 200) {
              this.$message.success('注册成功');
              this.active = 2;
            } else {
              this.$message.error(res.data.message);
            }
          });
        }
      })
    },
    handleRemove(file, fileList) {
      this.merchant.OTHER_FILE = null;
    },
    handleSuccess(response, file, fileList) {
      console.log(response);
      this.merchant.OTHER_FILE = response.data;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss">
.login {
  padding: 10px 20px 100px 20px;
  background-image: url("../assets/login-background.jpg");
  background-size: cover;
}

.title {
  margin: 0px auto 30px auto;
  text-align: center;
  color: #707070;
}

.login-main {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form {
  border-radius: 6px;
  background: #ffffff;
  width: 600px;
  padding: 25px 25px 5px 25px;

  .el-input {
    height: 38px;

    input {
      height: 38px;
    }
  }

  .input-icon {
    height: 39px;
    width: 14px;
    margin-left: 2px;
  }
}

.login-tip {
  font-size: 13px;
  text-align: center;
  color: #bfbfbf;
}

.el-login-footer {
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  font-family: Arial;
  font-size: 12px;
  letter-spacing: 1px;
}

.login-code-img {
  height: 38px;
}
</style>


